<template>
    <div>
        <div class="vx-row ml-1 mt-3">
            <div class="vx-row mb-6" style="width:70%">
                <div class="vx-col sm:w-1/4 w-full flex items-center">
                    <span>Work Order ID</span>
                </div>
                <div class="vx-col sm:w-3/4 w-full">
                    <WorkOrders 
                        :status="activeTab"
                        @selected="(val) => { this.selectedWorkOrder = val }"
                    ></WorkOrders>
                </div>
            </div>
            <div class="vx-row mb-6" style="width:70%">
                <div class="vx-col sm:w-1/4 w-full flex items-center">
                    <span>Execution Date</span>
                </div>
                <div class="vx-col sm:w-3/4 w-full">
                    <Date @selected="(val) => {
                        this.selectedExeDate = val
                    }"></Date>
                </div>
            </div>
            <div class="vx-row mb-6" style="width:70%">
                <div class="vx-col sm:w-1/4 w-full flex items-center">
                </div>
                <div class="vx-col sm:w-3/4 w-full">
                    <vs-button color="primary" @click="handleFilter()">Filter</vs-button>
                </div>
            </div>
        </div>
        <div class="vx-row mt-3">
            <div class="vx-col w-full mb-base">
                <Table :draw="draw"
                    :status="activeTab"
                    :work-order-id="(this.selectedWorkOrder) ? this.selectedWorkOrder.id : 0"
                    :execution-date="(this.selectedExeDate) ? this.selectedExeDate : ''">
                </Table>
            </div>
        </div>
    </div>
</template>

<script>
import WorkOrders from "./component/WorkOrders.vue";
import Date from "./component/Date.vue";
import Table from "./component/Table.vue";
export default {
    components: {
        WorkOrders,
        Date,
        Table
    },
    data() {
        return {
            activeTab: "Complete",
            draw: 0,
            selectedWorkOrder: null,
            selectedExeDate: null,
        }
    },
    methods: {
        handleFilter() {
            this.draw++;
        },
    }
}
</script>